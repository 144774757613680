.cocEvent {
  width: 100%;
  height: 100vh;
}
.cocEvent .container {
  width: 1200px;
  min-height: 700px;
  margin: 0 auto;
  height: auto;
  margin-bottom: 50px;
}
.cocEvent .container .topBd {
  height: 20px;
  width: 100%;
  background-color: #B80011;
}
.cocEvent .container .content {
  display: flex;
  flex-wrap: nowrap;
}
.cocEvent .container .content .sideNaw .nawName {
  width: 200px;
  height: 80px;
  background-color: #B80011;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  color: #fff;
  transition: all 0.1s ease-in 0.1s;
}
.cocEvent .container .content .sideNaw .navBarBar {
  background-color: #93000E;
}
.cocEvent .container .content .sideNaw .nawName:hover {
  background-color: #93000E;
  cursor: pointer;
}
.cocEvent .container .content .contentBox {
  margin: 20px 0 0 20px;
  width: 980px;
}
.cocEvent .container .content .contentBox .date {
  width: 69px;
  height: 26px;
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 26px;
  color: #B80011;
}
.cocEvent .container .content .contentBox .dateBox {
  margin: 40px 0 0 40px;
}
.cocEvent .container .content .contentBox .dateBox .dateYue {
  width: 32px;
  height: 26px;
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
  white-space: nowrap;
}
.cocEvent .container .content .contentBox .dateBox .dateText-1 {
  font-size: 16px;
  line-height: 21px;
  color: #666666;
  margin-top: 20px;
  cursor: pointer;
}
.cocEvent .container .content .contentBox .dateBox .dateText-1 .date-Text-2 {
  margin-right: 28px;
}
.cocEvent .container .content .contentBox .dateBox .listImg-1 {
  height: 140px;
  margin-top: 20px;
  margin-left: 19px;
  border-left: 1px solid #999;
  display: flex;
  padding-left: 45px;
}
.cocEvent .container .content .contentBox .dateBox .listImg-1 .listImg-2 {
  width: 280px;
  height: 140px;
  margin-right: 20px;
  cursor: pointer;
}
