.redStripNav .navItem {
  width: 200px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: #ffffff;
  background: #b80011;
  cursor: pointer;
}
.redStripNav .selecItems {
  width: 200px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: #93000e;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}
