.actCardList {
  width: 275px;
  height: 240px;
  border: 1px solid #DDDDDD;
  opacity: 1;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 20px;
}
.actCardList .photo {
  width: 100%;
  height: 132px;
  background-color: pink;
}
.actCardList .title {
  margin-top: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  width: 100%;
}
.actCardList .bottoms {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
}
.actCardList .bottoms .cityName {
  width: 50%;
  text-align: right;
}
.actCardList:nth-child(4n) {
  margin-right: 0;
}
.actCardList:hover div {
  color: #B80011 !important;
  cursor: pointer;
}
