.businessleft {
  width: 294px;
  height: 662px;
  background: #5F0000;
  opacity: 1;
  border-radius: 6px;
  padding-top: 30px;
  box-sizing: border-box;
}
.businessleft .title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 26px;
  color: #FFFFFF;
  margin-bottom: 20px;
  margin-left: 20px;
}
.businessleft .borderF {
  width: 25px;
  height: 4px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #fff;
  margin-left: 20px;
}
.businessleft .morebtn {
  width: 82px;
  height: 26px;
  border: 1px solid #FFFFFF;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 20px;
  margin-bottom: 30px;
  cursor: pointer;
}
.businessleft .bottom {
  width: 294px;
  height: 465px;
  background: #950A0A;
  border-radius: 6px;
  padding-top: 30px;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.businessleft .bottom .hotTitle {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 26px;
  color: #FFFFFF;
}
.businessleft .bottom .cardCardBoxs {
  width: 100%;
  padding-top: 20px;
  height: 244px;
}
.businessleft .bottom .cardCardBoxs .boxitem {
  display: inline-block;
  padding: 6px 2px;
  width: 80px;
  box-sizing: border-box;
  margin-right: 10px;
  font-size: 14px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #FFFFFF;
  background-color: #6C000A;
  height: 32px;
  margin-bottom: 4px;
  cursor: pointer;
}
.businessleft .bottom .photos {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 26px;
  color: #FFFFFF;
}
.businessleft .bottom .photos .photo {
  width: 114px;
  height: 114px;
  background: #FFF;
  border-radius: 6px;
  margin-right: 20px;
}
