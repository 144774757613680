.partyNewsCard {
  width: 463px;
  height: 433px;
  border: 1px solid #E9E6E6;
  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 0;
}
.partyNewsCard .first {
  display: flex;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F0F0F0;
}
.partyNewsCard .first .img {
  width: 152px;
  height: 102px;
  background-color: #707070;
  margin-right: 16px;
  min-width: 152px;
}
.partyNewsCard .first .newsContent {
  width: 238px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.partyNewsCard .first .newsContent .pTitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #333333;
  width: 100%;
}
.partyNewsCard .first .newsContent .pContainer {
  font-size: 14px;
  margin-top: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  color: #666666;
}
.partyNewsCard .first .dates {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  color: #666;
  text-align: right;
  width: 106%;
}
.partyNewsCard .newLists {
  width: 100%;
  height: 61px;
  border-bottom: 1px solid #F0F0F0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.partyNewsCard .newLists .right {
  width: 326px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
}
.partyNewsCard .newLists .left {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  color: #999999;
}
.partyNewsCard .newLists:last-child {
  border: none;
}
