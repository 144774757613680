.memberIndex {
  background-color: #F2F2F2;
  width: 100%;
}
.memberIndex .container {
  width: 1200px;
  margin: 0 auto;
}
.memberIndex .container .tabBorder {
  width: 100%;
  height: 20px;
  background-color: #B80011;
}
.memberIndex .container .topCard {
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
}
.memberIndex .container .topCard .leftCardInfo {
  width: 400px;
  height: 865px;
  padding-top: 20px;
  background: #FFFFFF;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 12px;
  margin-right: 40px;
}
.memberIndex .container .topCard .leftCardInfo .photo {
  display: block;
  width: 233px;
  height: 295px;
  margin: 0 auto;
  margin-bottom: 29px;
}
.memberIndex .container .topCard .leftCardInfo .cPostions {
  padding-left: 19px;
  box-sizing: border-box;
  margin-bottom: 18px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.memberIndex .container .topCard .leftCardInfo .infoBody1 {
  width: 361px;
  margin-left: 21px;
  margin-right: 18px;
  height: 209px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
}
.memberIndex .container .topCard .leftCardInfo .infoBody1 .name {
  padding-left: 19px;
  box-sizing: border-box;
  width: 100%;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 18px;
}
.memberIndex .container .topCard .leftCardInfo .infoBody2 {
  width: 361px;
  margin-top: 34px;
  margin-left: 21px;
  margin-right: 18px;
  box-sizing: border-box;
}
.memberIndex .container .topCard .leftCardInfo .infoBody2 .moreBtn {
  width: 280px;
  height: 44px;
  background: #B80011;
  border-radius: 22px;
  text-align: center;
  line-height: 44px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  margin-top: 51px;
  margin-left: 30px;
}
.memberIndex .container .topCard .descCard {
  width: 760px;
  height: 445px;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.memberIndex .container .topCard .descCard .title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 20px;
}
.memberIndex .container .topCard .descCard .desc {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.memberIndex .container .topCard .companyInfo {
  width: 760px;
  height: 400px;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
}
.memberIndex .container .topCard .companyInfo .title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.memberIndex .container .topCard .companyInfo .companyName {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
  width: 100%;
}
.memberIndex .container .topCard .companyInfo .photo {
  display: flex;
  margin-top: 15px;
}
.memberIndex .container .topCard .companyInfo .photo .photos {
  width: 143px;
  height: 143px;
  border-radius: 3px;
}
.memberIndex .container .topCard .companyInfo .photo .texts {
  align-self: flex-end;
  margin-left: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.memberIndex .container .companyDesc {
  width: 100%;
  height: 488px;
  overflow: auto;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
}
.memberIndex .container .companyDesc .title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  margin-bottom: 20px;
}
.memberIndex .container .relationCompany {
  width: 100%;
  min-height: 214px;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px 12px;
  box-sizing: border-box;
  margin-bottom: 30px;
}
.memberIndex .container .relationCompany .title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
}
.memberIndex .container .relationCompany .cardLists {
  margin-top: 20px;
  display: inline-block;
  width: 384px;
  height: 128px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  opacity: 1;
  border-radius: 6px;
  cursor: pointer;
  padding: 20px;
  padding-top: 19px;
  box-sizing: border-box;
  margin-right: 12px;
}
.memberIndex .container .relationCompany .cardLists .photo {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.memberIndex .container .relationCompany .cardLists .leftCard {
  height: 90px;
  width: 240px;
  margin-left: 20px;
}
.memberIndex .container .relationCompany .cardLists .leftCard .companyName {
  width: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.memberIndex .container .relationCompany .cardLists .leftCard .positions {
  width: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
}
.memberIndex .container .relationCompany .cardLists:nth-child(3n+1) {
  margin-right: 0 !important;
}
.bottomBtn {
  width: 12px;
  height: 12px;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
  transform: rotate(-45deg);
  display: inline-block;
  margin-bottom: 3px;
  margin-left: 20px;
}
