.businessIndex {
  width: 100%;
}
.businessIndex .banner {
  height: 650px;
  width: 100%;
  background-color: #000;
}
.businessIndex .centers {
  transform: translateX(-100px);
}
.businessIndex .container {
  width: 1200px;
  min-height: 200px;
  margin: 0 auto;
  position: relative;
}
.businessIndex .container .dynamic {
  width: 881px;
  height: 101px;
  right: 0;
  transform: translateY(-105%);
  position: absolute;
  display: block;
  z-index: 9;
}
.businessIndex .container .activitySwiper {
  margin: 50px auto 0 auto;
}
.businessIndex .container .activitySwiper .opportunityitem {
  width: 350px;
}
.businessIndex .container .activitySwiper .slick-current {
  transform: scale(1) !important;
}
.businessIndex .container .activitySwiper .slick-slide {
  transform: scale(0.84);
  width: none !important;
}
.businessIndex .container .activitySwiper .activitySwiperItem {
  width: 350px !important;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.businessIndex .container .activitySwiper .activitySwiperItem .actLoading {
  position: absolute;
  top: 15px;
  left: -45px;
  width: 160px;
  height: 35px;
  background-color: #B80011;
  transform: rotate(-45deg);
  line-height: 35px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
}
.businessIndex .container .activitySwiper .activitySwiperItem .pic {
  margin: 14px 15px 20px 15px;
  width: 320px;
  height: 208px;
  border-radius: 6px;
}
.businessIndex .container .activitySwiper .activitySwiperItem .content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.businessIndex .container .activitySwiper .activitySwiperItem .content .tit {
  width: 320px;
  font-size: 20px;
  color: #333333;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.businessIndex .container .activitySwiper .activitySwiperItem .content .infos {
  width: 320px;
  display: flex;
  justify-content: space-between;
}
.businessIndex .container .activitySwiper .activitySwiperItem .content .infos .con {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #666666;
  font-size: 14px;
}
.businessIndex .container .activitySwiper .activitySwiperItem .content .infos .con .address {
  width: 154px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.businessIndex .container .activitySwiper .activitySwiperItem .content .infos .con:first-child {
  margin-bottom: 10px;
}
.businessIndex .container .activitySwiper .activitySwiperItem .more {
  display: inline-block;
  margin: 20px auto;
  font-size: 10px;
  color: #999;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -ms-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}
.businessIndex .container .activitySwiper .activitySwiperItem:hover .more {
  color: #b70010;
}
.businessIndex .container .searchCard {
  width: 100%;
  min-height: 173px;
  background: #FFFFFF;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 6px;
  transform: translateY(-30px);
  padding-top: 50px;
  box-sizing: border-box;
}
.businessIndex .container .searchCard .serchInput {
  padding: 0 40px;
  height: 40px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
}
.businessIndex .container .searchCard .serchInput .seacrchBtn {
  width: 183px;
  height: 40px;
  background: #B80011;
  cursor: pointer;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
  transform: translateX(-1px);
}
.businessIndex .container .searchCard .serchInput .seacrchBtn:active {
  background-color: saddlebrown;
}
.businessIndex .container .searchCard .boCateCard {
  display: flex;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  align-items: center;
  margin-top: 23px;
}
.businessIndex .container .searchCard .boCateCard .leftItem {
  width: 1000px;
  min-height: 38px;
  display: flex;
  flex-wrap: wrap;
}
.businessIndex .container .searchCard .boCateCard .leftItem .leftItemCard {
  width: 125px;
  border-right: 1px solid #ddd;
  text-align: center;
  height: 12px;
  line-height: 12px;
  margin-bottom: 20px;
  cursor: pointer;
}
.businessIndex .container .searchCard .boCateCard .rightMoreBtn {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-left: 33px;
  color: #333333;
  transform: translateY(-10px);
  cursor: pointer;
}
.businessIndex .container .businessbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
}
.businessIndex .container .businessbox .right {
  margin-left: 20px;
  display: flex;
  margin-right: auto;
  flex-wrap: wrap;
}
.businessIndex .container .businessbox .right .opportunityitem:nth-child(3n) {
  margin-right: 0;
}
.businessIndex .container .cocCardContainer {
  margin: 31px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
}
.businessIndex .container .cocCardContainer .cocCard {
  width: 386px;
  height: 122px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.businessIndex .container .cocCardContainer .cocCard .photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 20px 10px 20px 20px;
}
.businessIndex .container .cocCardContainer .cocCard .bodyTainer {
  width: 260px;
  height: 80px;
  margin-top: 20px;
}
.businessIndex .container .cocCardContainer .cocCard .bodyTainer .cocName {
  height: 23px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24px;
  color: #333333;
  margin-bottom: 10px;
}
.businessIndex .container .cocCardContainer .cocCard .bodyTainer .texts {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #B80011;
  margin-bottom: 10px;
}
.businessIndex .container .cocCardContainer .cocCard .bodyTainer .site {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
}
.businessIndex .container .cocCardContainer .cocCard:nth-child(3n) {
  margin-right: 0;
}
.lookMore {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  width: 160px;
  height: 45px;
  background: #B80011;
  border-radius: 3px;
  line-height: 45px;
  text-align: center;
  margin: 10px auto;
  cursor: pointer;
}
.lookMore:active {
  background-color: saddlebrown;
}
