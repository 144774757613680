.actIndex .container {
  width: 1200px;
  margin: 0 auto;
}
.actIndex .container .tabBorder {
  width: 100%;
  height: 20px;
  background-color: #b80011;
}
.actIndex .container .activitySwiper {
  margin: 40px auto 0 auto;
}
.actIndex .container .activitySwiper .slick-current {
  transform: scale(1) !important;
}
.actIndex .container .activitySwiper .slick-slide {
  transform: scale(0.84);
}
.actIndex .container .activitySwiper .activitySwiperItem {
  width: 350px !important;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  padding: 15px;
  overflow: hidden;
}
.actIndex .container .activitySwiper .activitySwiperItem .actLoading {
  position: absolute;
  top: 15px;
  left: -45px;
  width: 160px;
  height: 35px;
  background-color: #B80011;
  transform: rotate(-45deg);
  line-height: 35px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
}
.actIndex .container .activitySwiper .activitySwiperItem .pic {
  width: 100%;
  height: 208px;
  border-radius: 6px;
  margin-bottom: 15px;
}
.actIndex .container .activitySwiper .activitySwiperItem .content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.actIndex .container .activitySwiper .activitySwiperItem .content .tit {
  width: 320px;
  font-size: 20px;
  color: #333333;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.actIndex .container .activitySwiper .activitySwiperItem .content .infos {
  width: 320px;
  display: flex;
  justify-content: space-between;
}
.actIndex .container .activitySwiper .activitySwiperItem .content .infos .con {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #666666;
  font-size: 14px;
}
.actIndex .container .activitySwiper .activitySwiperItem .content .infos .con .address {
  width: 154px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.actIndex .container .activitySwiper .activitySwiperItem .content .infos .con:first-child {
  margin-bottom: 10px;
}
.actIndex .container .activitySwiper .activitySwiperItem .more {
  display: inline-block;
  font-size: 10px;
  color: #999;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -ms-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}
.actIndex .container .activitySwiper .activitySwiperItem:hover .more {
  color: #b70010;
}
.actIndex .container .contnetSild {
  width: 100%;
  height: 579px;
  display: flex;
  align-items: center;
}
.actIndex .container .selectPages {
  display: flex;
  justify-content: center;
  margin-top: 42px;
  margin-bottom: 37px;
}
.actIndex .container .selectPages div {
  margin: 0;
}
.actIndex .container .actImgTitle {
  width: 100%;
  margin-top: 40px;
}
.actIndex .container .actCards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  min-height: 100px;
}
