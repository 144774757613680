.cocNewsInfo {
  width: 100%;
  height: 100vh;
}
.cocNewsInfo .container {
  width: 1200px;
  min-height: 200px;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.cocNewsInfo .container .redBar {
  width: 1200px;
  height: 20px;
  background: #b80011;
}
.cocNewsInfo .container .location {
  margin-top: 20px;
}
.cocNewsInfo .container .detailContent {
  display: flex;
  align-items: baseline;
}
.cocNewsInfo .container .detailContent .detail {
  margin: 40px 0 30px 40px;
}
.cocNewsInfo .container .detailContent .hotNews {
  margin-left: 55px;
}
