.MenberCards {
  display: flex;
  width: 210px !important;
  height: 270px;
  margin-bottom: 20px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  margin-right: 20px !important;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}
.MenberCards .photo {
  width: 97px;
  height: 136px;
  border-radius: 6px;
  overflow: hidden;
}
.MenberCards .name {
  width: 100%;
  font-size: 20px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-top: 10px;
}
.MenberCards .position {
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  line-height: 19px;
  color: #666666;
}
.MenberCards:last-child {
  margin-right: 0px;
}
