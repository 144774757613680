.homeShowindex {
  width: 100%;
  height: 100vh;
}
.homeShowindex .container {
  width: 1200px;
  min-height: 700px;
  margin: 0 auto;
  height: auto;
  margin-bottom: 50px;
}
.homeShowindex .container .topBd {
  height: 20px;
  width: 100%;
  background-color: #B80011;
}
.homeShowindex .container .content {
  display: flex;
  flex-wrap: nowrap;
}
.homeShowindex .container .content .sideNaw .nawName {
  width: 200px;
  height: 80px;
  background-color: #B80011;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  color: #fff;
  transition: all 0.1s ease-in 0.1s;
}
.homeShowindex .container .content .sideNaw .navBarBar {
  background-color: #93000E;
}
.homeShowindex .container .content .sideNaw .nawName:hover {
  background-color: #93000E;
  cursor: pointer;
}
.homeShowindex .container .content .mainBox {
  margin: 20px 0 0 20px;
  width: 980px;
}
.homeShowindex .container .content .mainBox .carouselBorder {
  width: 979px;
  height: 620px;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
}
.homeShowindex .container .content .mainBox .carouselBorder .carouselNaw {
  position: absolute;
  left: 50%;
  bottom: 20px;
  margin-left: -50px;
  z-index: 9;
  cursor: pointer;
}
.homeShowindex .container .content .mainBox .carouselBorder .carouselNaw .carouselNawDian {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  box-sizing: border-box;
  margin-left: 20px;
  border-radius: 5px;
}
.homeShowindex .container .content .mainBox .carouselBorder .carouselNaw .selectDian {
  background-color: #FFF;
}
.homeShowindex .container .content .mainBox .carouselBorder .carousel {
  width: 979px;
  height: 600px;
  position: relative;
}
.homeShowindex .container .content .mainBox .carouselBorder .carousel .carouselImg {
  width: 979px;
  height: 600px;
}
.homeShowindex .container .content .mainBox .carouselBorder .carousel .carouselTxt {
  display: block;
  width: 979px;
  height: 140px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.28s ease-in 0.28s;
}
.homeShowindex .container .content .mainBox .carouselBorder .carousel .carouselTxt .carouselTxt-1 {
  margin: 20px;
  font-size: 20px;
  color: #FFFFFF;
}
.homeShowindex .container .content .mainBox .carousel:hover {
  cursor: pointer;
}
.homeShowindex .container .content .mainBox .carousel:hover .carouselTxt {
  display: block;
  -webkit-animation: twinkling 1s 1 ease-in-out;
          animation: twinkling 1s 1 ease-in-out;
}
.homeShowindex .container .content .mainBox .news {
  height: 146px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  position: relative;
  cursor: pointer;
}
.homeShowindex .container .content .mainBox .news .bannerImg {
  width: 154px;
  height: 105px;
  border-radius: 4px;
  position: absolute;
  top: 20px;
  left: 0;
}
.homeShowindex .container .content .mainBox .news .newsTxt {
  margin-top: 19px;
  padding-left: 175px;
  font-size: 20px;
  color: #333333;
  position: relative;
}
.homeShowindex .container .content .mainBox .news .newsTxt .newsTxt-1 {
  width: 803px;
}
.homeShowindex .container .content .mainBox .news .newsTxt .date {
  position: absolute;
  right: 0;
  bottom: 10px;
  color: #999;
}
.homeShowindex .container .content .mainBox .news .newsTxt:hover {
  color: red !important;
}
@-webkit-keyframes twinkling {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes twinkling {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
