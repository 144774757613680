.opportunity {
  width: 100%;
  padding-left: 11px;
  box-sizing: border-box;
}
.opportunity .card {
  padding-left: 9px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 23px;
  display: flex;
  align-items: center;
}
.opportunity .card .items {
  width: 213px;
  height: 323px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 26px;
  position: relative;
  cursor: pointer;
}
.opportunity .card .items .codePhoto {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.opportunity .card .items .codePhoto .cPhoto {
  width: 196px;
  height: 196px;
  background: #fff;
  border-radius: 6px;
}
.opportunity .card .items .codePhoto .tips {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 10px;
}
.opportunity .card .items .codePhoto .tips:last-child {
  margin-top: 0;
}
.opportunity .card .items .type {
  position: absolute;
  width: 59px;
  height: 27px;
  background: #F6B3B3;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #B80011;
}
.opportunity .card .items .photo {
  display: block;
  margin: 0 auto;
  height: 132px;
  opacity: 1;
  border-radius: 3px;
}
.opportunity .card .items .title {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
}
.opportunity .card .items .price {
  height: 19px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
}
.opportunity .card .items:last-child {
  margin-right: 0;
}
