.cocDesc {
  width: 100%;
  height: 100vh;
}
.cocDesc .container {
  width: 1200px;
  min-height: 200px;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.cocDesc .container .topBd {
  height: 30px;
  width: 100%;
  background-color: #B80011;
}
.cocDesc .container .content {
  display: flex;
  flex-wrap: nowrap;
}
.cocDesc .container .content .sideNaw .nawName {
  width: 200px;
  height: 80px;
  background-color: #B80011;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  color: #fff;
  transition: all 0.1s ease-in 0.1s;
}
.cocDesc .container .content .sideNaw .navBarBar {
  background-color: #93000E;
}
.cocDesc .container .content .sideNaw .nawName:hover {
  background-color: #93000E;
  cursor: pointer;
}
.cocDesc .container .content .containers {
  width: 980px;
  padding: 20px;
  padding-right: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.cocDesc .container .content .containers .title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 20px;
}
