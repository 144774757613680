.shopListR {
  width: 365px;
  height: 684px;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
}
.shopListR .shopCaptionR {
  width: 335px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
}
.shopListR .shopCaptionR .captionTxtR {
  height: 22px;
  width: 92px;
  border-left: 2px solid #B80011;
  line-height: 22px;
  padding-left: 4px;
  font-size: 20px;
  color: #333333;
}
.shopListR .shopCaptionR .captionDuoR {
  margin-left: auto;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
.shopListR .shopListImgR {
  width: 364px;
  height: 630px;
  box-sizing: border-box;
  overflow: hidden;
}
.shopListR .shopListImgR .details {
  width: 364px;
  height: 88px;
  border-top: 1px solid #f2f2f2;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
}
.shopListR .shopListImgR .details .detailsLogo {
  height: 50px;
  width: 50px;
  margin: 20px 10px 0 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}
.shopListR .shopListImgR .details .company {
  height: 50px;
  width: 253px;
  margin-top: 20px;
}
.shopListR .shopListImgR .details .company .companyName {
  min-width: 253px;
  height: 21px;
  font-size: 16px;
  line-height: 16px;
  color: #666666;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.shopListR .shopListImgR .details .company .industry {
  width: 253px;
  height: 20px;
  display: flex;
  margin-top: 7px;
  overflow: hidden;
}
.shopListR .shopListImgR .details .company .industry .industryList {
  min-width: 53px;
  padding: 0 5px;
  height: 20px;
  border: 1px solid #B80011;
  border-radius: 10px;
  margin-right: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 18px;
  color: #B80011;
  text-align: center;
}
