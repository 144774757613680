.memberLists {
  background-color: #fff;
  width: 100%;
}
.memberLists .container {
  width: 1200px;
  margin: 0 auto;
}
.memberLists .container .tabBorder {
  width: 100%;
  height: 20px;
  background-color: #B80011;
}
.memberLists .container .leftPostion::-webkit-scrollbar {
  display: none;
}
.memberLists .container .bodyPostion {
  width: 100%;
  display: flex;
}
.memberLists .container .bodyPostion .leftPostion {
  width: 200px;
  background-color: #B80011;
  height: 600px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: auto;
  padding: 0 0.1rem;
  margin-bottom: -0.2rem;
  overflow: -moz-scrollbars-none;
}
.memberLists .container .bodyPostion .leftPostion .positionBox {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}
.memberLists .container .bodyPostion .leftPostion .positionBoxs {
  background-color: #93000E;
  font-size: 20px;
  font-family: Microsoft YaHei;
  color: #FFFFFF;
}
.memberLists .container .bodyPostion .leftPostion .positionBox:hover {
  background-color: #93000E;
  font-size: 20px;
  font-family: Microsoft YaHei;
  color: #FFFFFF;
}
.memberLists .container .bodyPostion .rightMember {
  width: 1000px;
  padding: 20px;
  box-sizing: border-box;
}
.memberLists .container .bodyPostion .rightMember .memberList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  min-height: 580px;
  margin-top: 20px;
}
.bottomBtn {
  width: 12px;
  height: 12px;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
  transform: rotate(-45deg);
  display: inline-block;
  margin-bottom: 3px;
  margin-left: 20px;
}
