.businessLists {
  width: 100%;
}
.businessLists .banner {
  height: 650px;
  width: 100%;
  background-color: #000;
}
.businessLists .container {
  width: 1200px;
  min-height: 700px;
  margin: 0 auto;
  position: relative;
}
.businessLists .container .checkBtn {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #B80011;
  cursor: pointer;
}
.businessLists .container .postBtn {
  width: 183px;
  height: 40px;
  background: #B80011;
  margin: 20px auto;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}
.businessLists .container .postBtn:active {
  background-color: saddlebrown;
}
.businessLists .container .tabBorder {
  width: 100%;
  height: 20px;
  background-color: #b80011;
}
.businessLists .container .serchInput {
  display: flex;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.businessLists .container .serchInput .seacrchBtn {
  cursor: pointer;
  width: 183px;
  height: 40px;
  background: #B80011;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
}
.businessLists .container .serchInput .seacrchBtn:active {
  background-color: saddlebrown;
}
.businessLists .container .tabslist {
  display: flex;
  width: 10%;
  height: 26px;
  margin-bottom: 10px;
}
.businessLists .container .tabslist .tabs {
  height: 26px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  cursor: pointer;
  color: #B80011;
}
.businessLists .container .boCateCard {
  display: flex;
  width: 100%;
  padding: 0 40px;
  padding-top: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  align-items: center;
  margin-top: 23px;
}
.businessLists .container .boCateCard .leftItem {
  width: 1000px;
  min-height: 38px;
  display: flex;
  flex-wrap: wrap;
}
.businessLists .container .boCateCard .leftItem .leftItemCard {
  width: 124px;
  border-right: 1px solid #ddd;
  text-align: center;
  height: 12px;
  line-height: 12px;
  margin-bottom: 20px;
  cursor: pointer;
}
.businessLists .container .boCateCard .rightMoreBtn {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-left: 33px;
  color: #333333;
  transform: translateY(-10px);
  cursor: pointer;
}
.businessLists .container .screen {
  margin: 20px 0;
  width: 100%;
  height: 40px;
  display: flex;
}
.businessLists .container .cardList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 620px;
}
.businessLists .container .cardList .opportunityitem:nth-child(4n) {
  margin-right: 0;
}
.cocCardContainer {
  margin: 31px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
}
.cocCardContainer .cocCard {
  width: 386px;
  height: 122px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.cocCardContainer .cocCard .photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 20px 10px 20px 20px;
}
.cocCardContainer .cocCard .bodyTainer {
  width: 260px;
  height: 80px;
  margin-top: 20px;
}
.cocCardContainer .cocCard .bodyTainer .cocName {
  height: 23px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24px;
  color: #333333;
  margin-bottom: 10px;
}
.cocCardContainer .cocCard .bodyTainer .texts {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #B80011;
  margin-bottom: 10px;
}
.cocCardContainer .cocCard .bodyTainer .site {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
}
.cocCardContainer .cocCard:nth-child(3n) {
  margin-right: 0;
}
