.detailPage {
  display: flex;
  flex-direction: column;
}
.detailPage .title {
  width: 742px;
  font-size: 28px;
  font-weight: bold;
  line-height: 45px;
  color: #333333;
  margin-bottom: 20px;
}
.detailPage .timeAuthor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #666666;
}
.detailPage .content {
  margin-top: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.detailPage .content img {
  max-width: 100%!important;
}
