.homeBanner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-left: 16px;
  margin-top: 20px;
  box-sizing: border-box;
}
.homeBanner .homeBannerbtn {
  width: 24px;
  height: 45px;
  background: #DDDDDD;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeBanner .box {
  display: flex;
  width: 610px;
  height: 158px;
}
.homeBanner .homeVides {
  display: flex !important;
  align-items: center;
}
.homeBanner .homeVides .homeVidese {
  width: 190px !important;
  height: 157px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
}
.homeBanner .homeVides .homeVidese .floating {
  height: 26px;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}
.homeBanner .homeVides .homeVidese:last-child {
  margin-right: 0;
}
.leftbtn {
  transform: rotate(180deg);
}
