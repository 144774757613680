.footer {
  width: 100%;
  height: 235px;
  background: #B80011;
  display: flex;
  justify-content: center;
}
.footer .box1 {
  width: 1078px;
  margin-top: 40px;
  min-height: 20px;
}
.footer .box1 .firstVlaues {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 5px;
}
.footer .box1 .fengex {
  width: 524px;
  height: 1px;
  background-color: #fff;
  margin-top: 17px;
  margin-bottom: 16px;
}
.footer .box4 {
  margin-top: 40px;
  text-align: center;
}
.footer .box4 .img {
  width: 122px;
  height: 122px;
  background: #FFF;
}
.footer .box4 .text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
  margin-top: 10px;
}
