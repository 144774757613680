.newListse {
  display: flex;
  flex-direction: column;
  width: 333px;
}
.newListse .newListItem {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F0F0F0;
}
.newListse .newListItem .newListItemL {
  display: flex;
  align-items: center;
}
.newListse .newListItem .newListItemL .newListItemLText {
  width: 263px;
  height: 21px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
}
.newListse .newListItem .newListItemL .newListItemLText:hover {
  color: #B80011 !important;
  cursor: pointer;
}
.newListse .newListItem .newListItemR {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #999999;
}
.newsBox {
  display: flex;
  width: 100%;
  height: 311px;
}
.newsBox .newsimgList {
  margin-top: 30px;
  margin-left: 22px;
  display: flex;
}
.newsBox .newsimgList .newsimgListBox {
  display: flex;
  flex-direction: column;
  width: 196px;
  margin-left: 19px;
}
.newsBox .newsimgList .photo {
  width: 196px;
  height: 196px;
  background-color: #ccc;
}
.newsBox .newsimgList .title {
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 25px;
  color: #666666;
}
.newsBox .newsimgListBox:hover div {
  color: #B80011 !important;
  cursor: pointer;
}
.newsBox .newsimgListBox:first-child {
  margin-left: 0;
}
