.PartyContentNewsCard {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 0;
}
.PartyContentNewsCard .first {
  display: flex;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F0F0F0;
}
.PartyContentNewsCard .first .img {
  width: 160px;
  height: 153px;
  background-color: #707070;
  margin-right: 16px;
  min-width: 152px;
}
.PartyContentNewsCard .first .newsContent {
  width: 367px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.PartyContentNewsCard .first .newsContent .pTitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #333333;
  width: 100%;
}
.PartyContentNewsCard .first .newsContent .pContainer {
  font-size: 14px;
  margin-top: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  color: #666666 !important;
}
.PartyContentNewsCard .first .dates {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  color: #999999;
  text-align: right;
  width: 103%;
  color: #666;
}
.PartyContentNewsCard .newLists {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #F0F0F0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PartyContentNewsCard .newLists .right {
  width: 326px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
}
.PartyContentNewsCard .newLists .left {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  color: #999999;
}
.PartyContentNewsCard .newLists:last-child {
  border: none;
}
