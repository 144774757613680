.cocRlt {
  width: 100%;
  height: 100vh;
}
.cocRlt .container {
  width: 1200px;
  min-height: 700px;
  margin: 0 auto;
  height: auto;
  margin-bottom: 50px;
}
.cocRlt .container .topBd {
  height: 30px;
  width: 100%;
  background-color: #B80011;
}
.cocRlt .container .content {
  display: flex;
  flex-wrap: nowrap;
}
.cocRlt .container .content .sideNaw .nawName {
  width: 200px;
  height: 80px;
  background-color: #B80011;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  color: #fff;
  transition: all 0.1s ease-in 0.1s;
}
.cocRlt .container .content .sideNaw .navBarBar {
  background-color: #93000E;
}
.cocRlt .container .content .sideNaw .nawName:hover {
  background-color: #93000E;
  cursor: pointer;
}
.cocRlt .container .content .contentBoxs {
  margin: 20px 0 0 20px;
  display: flex;
  flex-wrap: wrap;
  width: 980px;
}
.cocRlt .container .content .contentBoxs .cocCard {
  cursor: pointer;
  width: 482px;
  height: 151px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  margin-right: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.cocRlt .container .content .contentBoxs .cocCard .photo {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  margin-right: 20px;
}
.cocRlt .container .content .contentBoxs .cocCard .bodyTainer {
  height: 112px;
  width: 307px;
}
.cocRlt .container .content .contentBoxs .cocCard .bodyTainer .cocName {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 11px;
}
.cocRlt .container .content .contentBoxs .cocCard .bodyTainer .texts {
  height: 19px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.cocRlt .container .content .contentBoxs .cocCard:nth-child(2n) {
  margin-right: 0;
}
