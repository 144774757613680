.newsList {
  width: 100%;
  height: 100vh;
}
.newsList .redStrip {
  margin: 0 auto;
  width: 1200px;
  height: 20px;
  background: #b80011;
}
.newsList .container {
  width: 1200px;
  min-height: 200px;
  margin: 0 auto;
  display: flex;
}
.newsList .container .cocNewsItems {
  margin-left: 18px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
}
.newsList .container .cocNewsItems .noDataImg {
  margin: 130px auto auto 273px;
  width: 383px;
  height: 334px;
}
.newsList .container .cocNewsItems .paginationBox {
  margin: 30px auto 60px auto;
}
