.catesOne {
  width: 100%;
  border-top: 1px solid #E6E0E1;
  padding-top: 18px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.catesOne .newsCard {
  width: 100%;
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
}
.catesOne .newsCard .left {
  width: 707px;
  height: 540px;
  padding-top: 19px;
  box-sizing: border-box;
}
.catesOne .newsCard .left .newCard {
  width: 100%;
  height: 283px;
  display: flex;
  padding-bottom: 38px;
  box-sizing: border-box;
  border-bottom: 1px solid #E6E0E1;
  justify-content: space-between;
  cursor: pointer;
}
.catesOne .newsCard .left .newCard .photo {
  width: 351px;
  height: 100%;
}
.catesOne .newsCard .left .newCard .containers {
  width: 320px;
  height: 100%;
}
.catesOne .newsCard .left .newCard .containers .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 9px;
}
.catesOne .newsCard .left .newCard .containers .texts {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.catesOne .newsCard .left .newCards {
  width: 100%;
  padding-left: 21px;
  box-sizing: border-box;
  margin-top: 12px;
  cursor: pointer;
}
.catesOne .newsCard .left .newCards .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 12px;
}
.catesOne .newsCard .left .newCards .contarners {
  display: flex;
  justify-content: space-between;
}
.catesOne .newsCard .left .newCards .contarners .texts {
  width: 433px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.catesOne .newsCard .left .newCards .contarners .photos {
  width: 230px;
  height: 175px;
}
.catesOne .newsCard .right {
  width: 474px;
  height: 540px;
  border: 1px solid #E2E3E4;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.catesOne .newsCard .right .photo {
  width: 100%;
  height: 280px;
}
.catesOne .newsCard .right .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 9px;
}
.catesOne .newsCard .right .texts {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
