.tabsbox {
  width: auto;
  display: flex;
  margin-left: 50px;
}
.tabs {
  display: flex;
  height: 34px;
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #D9D9D9;
}
.tabs .selctTabsBat {
  width: 89px;
  height: 100%;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-left: 1px solid #D9D9D9;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
}
.tabs .selctTabsBat:first-child {
  border-left: none;
}
.tabs .tabsBat {
  border: 1px solid #B80011 !important;
  color: #B80011;
}
