.banner {
  cursor: pointer;
  height: 650px;
  width: 100%;
}
.checkouDotse {
  position: absolute;
  z-index: 9;
  bottom: 17px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  cursor: pointer;
}
.checkouDotse .checkouDotsList {
  width: 12px;
  height: 12px;
  background: red;
  border-radius: 50%;
  margin-right: 12px;
}
.checkouDotse .checkouDotsLists {
  width: 12px;
  height: 12px;
  background: #FFF;
  border-radius: 50%;
  margin-right: 12px;
}
