.hotNews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  min-height: 200px;
}
.hotNews .newsBanner {
  width: 698px;
  height: 343px;
  margin-left: 13px;
  background-color: #ccc;
  margin-right: 20px;
  position: relative;
  box-sizing: border-box;
}
.hotNews .newsBanner .btn {
  position: absolute;
  width: 12px;
  height: 12px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
}
.hotNews .newsBanner .checkouDots {
  position: absolute;
  z-index: 9;
  bottom: 17px;
  right: 20px;
  display: flex;
  cursor: pointer;
}
.hotNews .newsBanner .checkouDots .checkouDotsList {
  width: 6px;
  height: 6px;
  background: #696969;
  border-radius: 50%;
  margin-right: 8px;
}
.hotNews .newsBanner .checkouDots .checkouDotsLists {
  width: 6px;
  height: 6px;
  background: #FFF;
  border-radius: 50%;
  margin-right: 8px;
}
.hotNews .newsBanner .btn1 {
  left: 20px;
  transform: rotate(45deg);
}
.hotNews .newsBanner .btn2 {
  right: 20px;
  transform: rotate(-135deg);
}
.hotNews .newsBanner .newsBannerItems {
  width: 100%;
  height: 100%;
}
.hotNews .newsBanner .newsBannerItem {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.hotNews .newsBanner .newsBannerItem .bannerImg {
  width: 698px;
  height: 343px;
}
.hotNews .newsBanner .newsBannerItem .floating {
  height: 41px;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  padding-right: 20%;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #FFFFFF;
}
.hotNews .newList {
  display: flex;
  flex-direction: column;
  width: 463px;
  transform: translateY(-19px);
}
.hotNews .newList .newListItem {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F0F0F0;
}
.hotNews .newList .newListItem .newListItemL {
  display: flex;
  align-items: center;
}
.hotNews .newList .newListItem .newListItemL .newListItemLText {
  width: 300px;
  height: 21px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #333;
}
.hotNews .newList .newListItem .newListItemL .newListItemLText:hover {
  color: #B80011 !important;
  cursor: pointer;
}
.hotNews .newList .newListItem .newListItemR {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #999999;
}
