.actCard {
  width: 100%;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.actCard .goto {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}
.actCard .listCard {
  padding-left: 19px;
  padding-right: 15px;
  width: 100%;
}
.actCard .listCard .listItem {
  height: 127px;
  width: 100%;
  border-bottom: 1px solid #E9E6E6;
  display: flex;
  align-items: flex-end;
  padding-bottom: 15px;
  box-sizing: border-box;
  cursor: pointer;
}
.actCard .listCard .listItem .photo {
  width: 103px;
  height: 94px;
  background-color: #ccc;
  margin-right: 16px;
}
.actCard .listCard .listItem .content {
  height: 94px;
  width: 421px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.actCard .listCard .listItem .content .title {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 25px;
  color: #333333;
}
.actCard .listCard .listItem .content .dates {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #666;
}
.actCard .listCard .listItem:hover div {
  color: #B80011 !important;
}
