body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  /*早期浏览器*/
  user-select: none;
}
div {
  word-break: break-all;
}
body::-webkit-scrollbar {
  display: none;
}
* {
  padding: 0;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
/* 头部标题 */
.comTitle {
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 2px solid #b80011;
  padding-left: 11px;
  box-sizing: border-box;
}
.comTitle .comTitleR {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.comTitle .comTitleL {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  color: #999999;
  cursor: pointer;
}
/* 溢出隐藏 */
.overHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* // 小圆点 */
.dots {
  width: 6px;
  height: 6px;
  background: #b80011;
  border-radius: 50%;
  margin-right: 8px;
}
/* 两行溢出隐藏 */
.twoOverHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
}
/* 三行溢出隐藏 */
.threeOverHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 3;
}
/* 10行溢出隐藏 */
.oneOneOverHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 10;
}
/* 8行溢出隐藏 */
.eightOverHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 8;
}
/* 6行溢出隐藏 */
.sixOverHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 6;
}
/* 5行溢出隐藏 */
.fiveOverHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 5;
}
.fourOverHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 4;
}
.hoverCored:hover {
  color: #b80011 !important;
  cursor: pointer;
}
.hoverCored:hover div {
  color: #b80011 !important;
  cursor: pointer;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #93000e;
  border-color: #93000e;
}
.ant-pagination-item-active a {
  color: #ffffff !important;
}
.ant-pagination-item:focus a {
  color: #ffffff;
}
.ant-pagination-item:hover a {
  color: #93000e;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #ffffff;
}
.ant-pagination-item:active a,
.ant-pagination-item:visited a {
  color: #ffffff;
}
.ant-pagination-item:focus {
  border-color: #93000e;
  color: #ffffff;
  transition: all 0.3s;
}
.ant-pagination-item:hover {
  border-color: #93000e;
  color: #ffffff;
  transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #93000e;
  color: #93000e;
}
.ant-popover-arrow {
  display: none;
}
.ant-popover-inner-content {
  padding: 0 !important;
  border-radius: 12px;
  width: 0;
}
.memberListIndex {
  background-color: #fff;
  min-width: 740px;
  height: 430px;
  overflow: auto;
  border-radius: 12px;
  box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.16);
  opacity: 1;
  transform: translateX(-100%);
}
.memberListIndex .title {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #333333;
  height: 80px;
  line-height: 80px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
}
.memberListIndex .sessionList {
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
}
.memberListIndex .sessionList .sessionitem {
  width: 150px;
  height: 50px;
  background: #fff;
  opacity: 1;
  border-radius: 6px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #B80011;
  text-align: center;
  border: 1px solid #B80011;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.memberListIndex .sessionList .sessionitem:nth-child(4n) {
  margin-right: 0;
}
.memberListIndex .sessionList .sessionitemse {
  color: #ffff;
  background: #B80011;
}
.memberListIndexs {
  background-color: #fff;
  min-width: 740px;
  height: 430px;
  overflow: auto;
  border-radius: 12px;
  box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.16);
  opacity: 1;
  transform: translateX(-10%);
}
.memberListIndexs .title {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #333333;
  height: 80px;
  line-height: 80px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
}
.memberListIndexs .sessionList {
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
}
.memberListIndexs .sessionList .sessionitem {
  width: 150px;
  height: 50px;
  background: #fff;
  opacity: 1;
  border-radius: 6px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #B80011;
  text-align: center;
  border: 1px solid #B80011;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.memberListIndexs .sessionList .sessionitem:nth-child(4n) {
  margin-right: 0;
}
.memberListIndexs .sessionList .sessionitemse {
  color: #ffff;
  background: #B80011;
}
.hotBoCateList {
  width: 450px;
  height: 162px;
  background: #FFFFFF;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  transform: translateX(-20%);
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}
.hotBoCateList .leftItemCard {
  width: 130px;
  border-right: 1px solid #ddd;
  text-align: center;
  height: 12px;
  line-height: 12px;
  margin-bottom: 24px;
  cursor: pointer;
}
.hotBoCateList .leftItemCard:nth-child(3n) {
  border-right: none;
}
.cocNewsBgc {
  width: 100%;
  height: 0;
  padding-bottom: 83%;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
