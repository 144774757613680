.newsHotRec {
  display: flex;
  flex-direction: column;
}
.newsHotRec .titleMore {
  margin-bottom: 15px;
  border-left: 2px solid #b80011;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newsHotRec .titleMore .hot {
  padding-left: 10px;
  height: 18px;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  color: #333333;
}
.newsHotRec .titleMore .lookMores {
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}
.newsHotRec .list .itemView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  width: 363px;
  padding: 20px 0;
}
.newsHotRec .list .itemView .title {
  width: 331px;
  text-align: left;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333333;
}
.newsHotRec .list .itemView .itemImg {
  width: 331px;
  height: 153px;
}
.newsHotRec .list .itemView:first-child {
  padding: 15px 0 20px 0 !important;
}
