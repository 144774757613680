.actInfo {
  width: 100%;
  height: 100vh;
}
.actInfo .container {
  width: 1200px;
  min-height: 200px;
  margin: 0 auto;
}
.actInfo .container .tabBorder {
  width: 100%;
  height: 20px;
  background-color: #b80011;
}
.actInfo .container .actBody {
  display: flex;
  width: 100%;
  margin-top: 21px;
}
.actInfo .container .actBody .photo {
  min-width: 508px;
  height: 378px;
  margin-right: 20px;
}
.actInfo .container .actBody .rightText {
  height: 378px;
  display: flex;
  flex-direction: column;
}
.actInfo .container .actBody .rightText .title {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 45px;
  color: #333333;
  margin-bottom: 20px;
}
.actInfo .container .actBody .rightText .valueBox {
  width: 674px;
  height: 269px;
  background: #FFFFFF;
  background-image: url('../../../asset/images/actInfo.png');
  background-size: 100% 100%;
  display: flex;
  padding: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  flex-wrap: wrap;
}
.actInfo .container .actBody .rightText .valueBox .label {
  display: flex;
  width: 100%;
  height: 32px;
}
.actInfo .container .actBody .rightText .valueBox .label .price {
  margin-left: auto;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B80011;
}
.actInfo .container .actBody .rightText .valueBox .bottom {
  align-self: flex-end;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B80011;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
}
.actInfo .container .actBody .rightText .valueBox .bottom .btn {
  width: 200px;
  height: 40px;
  background: #B80011;
  border-radius: 3px;
  margin-left: auto;
  color: #fff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.actInfo .container .actBody .rightText .valueBox .bottom .btn:active {
  background-color: #c70000;
}
