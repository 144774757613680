.memberIndex {
  background-color: #f2f2f2;
  width: 100%;
}
.memberIndex .container {
  width: 1200px;
  margin: 0 auto;
}
.memberIndex .container .tabBorder {
  width: 100%;
  height: 20px;
  background-color: #b80011;
}
.memberIndex .container .screen {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 68px;
  align-items: center;
}
.memberIndex .container .screen .backSessionBtn {
  cursor: pointer;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #333333;
  display: flex;
  align-items: center;
}
.memberIndex .container .screen .nobackSessionBtn {
  cursor: not-allowed;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #666;
  display: flex;
  align-items: center;
}
.memberIndex .container .screen .gotoBtn {
  display: flex;
  align-items: center;
}
.memberIndex .container .screen .gotoBtn .nextSessionBtn {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #333333;
  cursor: pointer;
}
.memberIndex .container .screen .gotoBtn .nowSession {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b80011;
  padding: 0 31px;
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
  margin: 0 31px;
  height: 26px;
  line-height: 26px;
}
.memberIndex .container .screen .gotoBtn .noSessionBtn {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #999999;
  cursor: not-allowed;
}
.memberIndex .container .screen .selectSession {
  cursor: pointer;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 45px;
  color: #333333;
  display: flex;
  align-items: center;
}
.memberIndex .container .screen .selectSession .bottomClass {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(-45deg);
  margin-left: 19px;
}
.memberIndex .container .positonFirst {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.memberIndex .container .positonFirst .positonFirstCard {
  width: 585px;
  height: 496px;
  border-radius: 12px;
  background-color: #fff;
  padding: 20px;
  margin-right: 31px;
  cursor: pointer;
  box-sizing: border-box;
}
.memberIndex .container .positonFirst .positonFirstCard .top {
  display: flex;
  margin-top: 20px;
}
.memberIndex .container .positonFirst .positonFirstCard .top .photo {
  width: 140px;
  height: 182px;
  border-radius: 6px;
  margin-right: 20px;
}
.memberIndex .container .positonFirst .positonFirstCard .top .right {
  width: 385px;
  border-bottom: 1px solid #ddd;
}
.memberIndex .container .positonFirst .positonFirstCard .top .right .name {
  width: 100%;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  color: #333333;
  margin-bottom: 20px;
}
.memberIndex .container .positonFirst .positonFirstCard .top .right .positions {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
  margin-bottom: 11px;
}
.memberIndex .container .positonFirst .positonFirstCard .descTitle {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.memberIndex .container .positonFirst .positonFirstCard .desc {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
}
.memberIndex .container .positonFirst .positonFirstCard:nth-child(2n) {
  margin-right: 0;
}
.memberIndex .container .positionCardTwo {
  width: 100%;
  border-radius: 12px;
  height: 426px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
}
.memberIndex .container .positionCardTwo .positionsBox {
  display: flex;
  margin-top: 20px;
}
.memberIndex .container .positionCardTwo .positionsBox .positonFirstCard {
  width: 373px;
  height: 341px;
  border-radius: 12px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  margin-right: 21px;
  cursor: pointer;
}
.memberIndex .container .positionCardTwo .positionsBox .positonFirstCard .top {
  display: flex;
  margin-bottom: 20px;
}
.memberIndex .container .positionCardTwo .positionsBox .positonFirstCard .top .photo {
  width: 100px;
  height: 130px;
  border-radius: 6px;
  margin-right: 22px;
}
.memberIndex .container .positionCardTwo .positionsBox .positonFirstCard .top .right {
  width: 211px;
  border-bottom: 1px solid #ddd;
}
.memberIndex .container .positionCardTwo .positionsBox .positonFirstCard .top .right .name {
  width: 100%;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  color: #333333;
  margin-bottom: 20px;
}
.memberIndex .container .positionCardTwo .positionsBox .positonFirstCard .top .right .positions {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
  margin-bottom: 11px;
}
.memberIndex .container .positionCardTwo .positionsBox .positonFirstCard .descTitle {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  width: 100%;
  margin-bottom: 10px;
}
.memberIndex .container .positionCardTwo .positionsBox .positonFirstCard .desc {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
}
.memberIndex .container .positionCardTwo .positionsBox .positonFirstCard:nth-child(3n) {
  margin-right: 0;
}
.memberIndex .container .morePositionCard {
  width: 100%;
  height: 658px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 30px;
}
.memberIndex .container .morePositionCard .titleTabs {
  display: flex;
  height: 77px;
  width: 100%;
  padding: 0 22px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .bannerBody {
  width: 1070px;
  margin-top: 10px;
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .bannerBody .slick-slide {
  text-align: center;
  width: 100px;
  min-width: 40px;
  font-size: 20px;
  color: #333333;
  font-weight: bold;
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .bannerBody .slick-prev::before,
.memberIndex .container .morePositionCard .titleTabs .bannerSild .bannerBody .slick-next::before {
  content: "";
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .bannerBody .slick-prev,
.memberIndex .container .morePositionCard .titleTabs .bannerSild .bannerBody .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 60px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .bannerBody .post {
  padding-bottom: 10px;
  cursor: pointer;
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .bannerBody .slick-currents {
  position: relative;
  font-size: 20px;
  color: #b80011;
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .bannerBody .slick-currents::before {
  content: "";
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 4px;
  border-radius: 30px;
  border-bottom: 4px solid #b80011;
  transition: 0.2s all linear;
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .btn {
  width: 14px;
  height: 14px;
  border-bottom: 2px solid #666;
  border-left: 2px solid #666;
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .peryBtn {
  transform: rotate(45deg);
}
.memberIndex .container .morePositionCard .titleTabs .bannerSild .nextBtn {
  transform: rotate(-135deg);
}
.memberIndex .container .morePositionCard .titleTabs .moreBtn {
  width: 72px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
.memberIndex .container .morePositionCard .positionBody {
  padding-left: 40px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
