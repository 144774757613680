.repostiTitons {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}
.repostiTitons .box {
  display: flex;
  flex-direction: column;
  width: 511px;
  justify-content: center;
  align-items: center;
}
.repostiTitons .box .closer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  margin-left: auto;
  margin-bottom: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.repostiTitons .box .photo {
  width: 347px;
  height: 347px;
  background-color: #fff;
}
.repostiTitons .box .texts {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 27px;
}
