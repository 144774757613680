.cocNewsItem {
  width: 980px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2ff;
  cursor: pointer;
}
.cocNewsItem .newsImg {
  border-radius: 6px;
  width: 259px;
  height: 175px;
  margin-right: 20px;
}
.cocNewsItem .newsContent {
  display: flex;
  flex-direction: column;
}
.cocNewsItem .newsContent .title {
  text-align: left;
  font-size: 20px;
  color: #333333;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.cocNewsItem .newsContent .desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
  width: 702px;
  height: 85px;
  font-size: 16px;
  line-height: 30px;
  color: #666666;
}
.cocNewsItem .newsContent .time {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  color: #999999;
  letter-spacing: 0.40000001px;
}
