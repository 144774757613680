.cocListImg {
  width: 100%;
  padding-bottom: 21px;
  border-bottom: 1px solid #E6E0E1;
  margin: 20px 0;
  padding-left: 19px;
  box-sizing: border-box;
  min-height: 100px;
  display: flex;
}
.cocListImg .cocListItem {
  width: 122px;
  height: 123px;
  background-color: #ccc;
  margin-left: 22px;
  position: relative;
  cursor: pointer;
}
.cocListImg .cocListItem img {
  width: 100%;
  height: 100%;
}
.cocListImg .cocListItem .flating {
  width: 100%;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 0;
  padding: 0 11px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 25px;
  color: #FFFFFF;
}
.cocListImg .cocListItem:first-child {
  margin-left: 0px;
}
