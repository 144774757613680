.cocNewsindex {
  width: 100%;
  height: 100vh;
}
.cocNewsindex .container {
  width: 1200px;
  min-height: 200px;
  margin: 0 auto;
  height: auto;
}
.cocNewsindex .container .activitySwiper {
  margin: 40px auto 0 auto;
  margin-bottom: 30px;
}
.cocNewsindex .container .activitySwiper .slick-current {
  transform: scale(1) !important;
}
.cocNewsindex .container .activitySwiper .slick-slide {
  transform: scale(0.84);
}
.cocNewsindex .container .activitySwiper .activitySwiperItem {
  width: 624px;
  height: 340px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border: 1px solid #ddd;
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}
.cocNewsindex .container .activitySwiper .activitySwiperItem .tipsBgc {
  width: 359px;
  height: 47px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}
.cocNewsindex .container .cateNews {
  margin-top: 31px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
  margin-bottom: 30px;
}
