.index {
  width: 100%;
  height: 100%;
}
.index .container {
  width: 1200px;
  margin: 0 auto;
}
.index .container .special {
  width: 100%;
  margin-top: 30px;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
}
.index .container .special .left {
  width: 60px;
  height: 247px;
  background: #b80011;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding: 0 18px;
  box-sizing: border-box;
}
.index .container .special .right {
  display: flex;
  position: relative;
  width: 1140px;
  height: 247px;
}
.index .container .special .right .btn {
  width: 12px;
  height: 12px;
  border-bottom: 1px solid #070707;
  border-left: 1px solid #070707;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.index .container .special .right .newList {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 7px;
  padding-left: 30px;
  padding-right: 20px;
  box-sizing: border-box;
}
.index .container .special .right .newList .newItems {
  width: 199px !important;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
}
.index .container .special .right .newList .newItems .imgs {
  width: 100%;
  height: 118px;
  background-color: #ccc;
}
.index .container .special .right .newList .newItems .title {
  width: 100%;
  margin-top: 8px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  color: #333333;
}
.index .container .special .right .newList .newItems .content {
  color: #666666;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 100%;
}
.index .container .special .right .newList .slick-prev::before,
.index .container .special .right .newList .slick-next::before {
  content: "";
}
.index .container .special .right .newList .slick-next {
  right: -38px;
}
.index .container .special .right .newList .slick-prev,
.index .container .special .right .newList .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  z-index: 9;
  display: block;
  width: 40px;
  height: 60px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.index .container .special .right .btnL {
  left: 11px;
  transform: translateY(-50%) rotate(45deg);
}
.index .container .special .right .btnR {
  right: 11px;
  transform: translateY(-50%) rotate(-135deg);
}
.index .container .newsAct {
  width: 100%;
  margin-top: 30px;
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid #e9e6e6;
}
.index .container .newsAct .partyListnews {
  width: 596px;
  height: 684px;
  margin-right: 30px;
  border: 1px solid #e9e6e6;
}
.index .container .newsAct .partyListAct {
  width: 574px;
  height: 684px;
  border: 1px solid #e9e6e6;
}
.index .container .cateNews {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e9e6e6;
}
