.pages {
  width: 100%;
  height: 100px;
  background-color: #fff;
  z-index: 0;
}
.tabBar {
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
  background-color: #fff;
}
.tabBar .logo {
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}
.tabBar .cocName {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 40px;
  color: #E02020;
  margin-right: 100px;
  cursor: pointer;
}
.tabBar .items {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  color: #333333;
  margin-right: 33px;
  cursor: pointer;
}
.tabBar .items:hover {
  color: #B80011;
}
.tabBar .selecItems {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 26px;
  color: #B80011;
  margin-right: 33px;
  cursor: pointer;
}
